import React, { useContext } from 'react';
import Marquee from "react-fast-marquee";
import { ThemeContext } from '../../../contexts/theme-context';
import { skillsData } from '../../../data/skillsData';
import './skills.css';
import { Link } from 'react-router-dom';

function Skills() {
    const { theme } = useContext(ThemeContext);

    const skillBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 12px ${theme.primary}`
    }

    return (
        <div className="skills" style={{ backgroundColor: theme.secondary }}>
            <div className="skillsHeader">
                <h2 style={{ color: theme.primary }}>Ponentes</h2>
            </div>
            <div className="skillsContainer">
                <div className="skill--scroll">
                    <Marquee
                        gradient={false}
                        speed={80}
                        pauseOnHover={true}
                        pauseOnClick={true}
                        delay={0}
                        play={true}
                        direction="left"
                    >
                        {skillsData.map((skill, id) => (
                            <Link to={"/blog/"+(id+1)}>
                                <div className="skill--box" key={id} style={skillBoxStyle}>
                                    <img src={skill.image} alt={skill.speaker} style={{borderRadius: "50%"}} />
                                    <h3 style={{ color: theme.tertiary }}>
                                        {skill.speaker}
                                    </h3>
                                </div>
                            </Link>
                        ))}
                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default Skills
