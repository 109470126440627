function timeConverter(isoTime) {
  const currentTime = new Date().getTime();
  const pastTime = new Date(isoTime).getTime();
  const timeDifference = currentTime - pastTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return seconds!==1 ? `Hace ${seconds} segundos` : `Hace ${seconds} segundo`;
  } else if (minutes < 60) {
    return minutes!==1 ? `Hace ${minutes} minutos` : `Hace ${minutes} minuto`;
  } else if (hours < 24) {
    return hours!==1 ? `Hace ${hours} horas` : `Hace ${hours} hora`;
  } else if (days < 30) {
    return days!==1 ? `Hace ${days} días` : `Hace ${days} día`;
  } else if (months < 12) {
    return months!==1 ? `Hace ${months} meses` : `Hace ${months} mes`;
  } else {
    return years!==1 ? `Hace ${years} años` : `Hace ${years} año`;
  }
}

function parseDate(date) {
  if (!date) return '';
  let month = date.split(' ')[0].trim() === 'January' ? 'enero' : 'diciembre';
  let day = date.split(' ')[1].split(',')[0].trim();
  let year = date.split(' ')[2].trim();

  return `${day} de ${month} de ${year}`;
}

export { timeConverter, parseDate };