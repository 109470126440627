import React, { createContext, useEffect, useState } from 'react';

import { theDarkTheme, theLightTheme } from '../theme/theme';

export const ThemeContext = createContext()

function ThemeContextProvider(props) {
    // eslint-disable-next-line
    const [theme, setTheme] = useState(theDarkTheme);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isDark, setDark] = useState(true);

    const setHandleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    const changeTheme = () => {
        if (isDark) {
            setTheme(theLightTheme);
            setDark(false);
        } else {
            setTheme(theDarkTheme);
            setDark(true);
        } 
    }

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        if (localTheme) {
            try {
                setTheme(JSON.parse(localTheme));
                setDark(JSON.parse(localTheme).type !== 'light');
            }
            catch (e) {
                setTimeout(() => {
                    setTheme(theDarkTheme);
                    setDark(true);
                }, 3000);
            }
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(theme));
    }, [theme]);

    const value = { theme, drawerOpen, setHandleDrawer, changeTheme, isDark }
    return (
        <ThemeContext.Provider value={value}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export default ThemeContextProvider