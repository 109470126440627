import { Container } from '@mui/material';
import React, { useContext } from 'react';
import codings from '../../../assets/lottie/coding.json';
import { ThemeContext } from '../../../contexts/theme-context';
import { aboutData } from '../../../data/aboutData';
import AnimationLottie from '../../helper/animation-lottie';
import Typed from 'react-typed';
import './about.css';
import builds from '../../../assets/lottie/build.json';
import educations from '../../../assets/lottie/education.json';

function About() {

    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ backgroundColor: theme.secondary }}>
            <Container className="about" id="about">
                <div className="line-styling">
                    <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                    <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                    <div className="style-line" style={{ backgroundColor: theme.primary }}></div>
                </div>
                <div className="about-body">
                    <div className="about-description">
                        <h2 style={{ color: theme.primary }}>{aboutData.title}</h2>
                        <p style={{ color: theme.tertiary }}>{aboutData.description1}</p>
                    </div>
                    <div className="about-animation">
                        <AnimationLottie animationPath={codings} />
                    </div>
                </div>
                <div className="about-body">
                    <div className="about-description">
                        <h2 style={{ color: theme.primary }}>{aboutData.title2}</h2>
                        <p style={{ color: theme.tertiary }}>{aboutData.description2}<br /><br />{aboutData.description3}</p>
                    </div>
                    <div className="about-animation">
                        <AnimationLottie animationPath={builds} />
                    </div>
                </div>
                <div className="about-body">
                    <div className="about-description">
                        <div className="one-line">
                            <h2 style={{ color: theme.primary, marginRight: "10px" }}>¿Qué </h2>
                            <Typed
                                strings={[
                                    'espero',
                                    'esperaba',]}
                                typeSpeed={40}
                                backSpeed={100}
                                style={{ color: theme.primary, marginBottom: "40px",
                                    fontSize: "2.5rem", fontWeight: "bold", fontFamily: "var(--primaryFont)"}}
                                loop
                            />
                            <h2 style={{ color: theme.primary }}> de la asignatura?</h2>
                        </div>
                        <p style={{ color: theme.tertiary }}>{aboutData.description4}<br /><br />{aboutData.description5}</p>
                    </div>
                    <div className="about-animation2">
                        <AnimationLottie animationPath={educations}/>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default About;
