import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import ThemeContextProvider from './contexts/theme-context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { CircularProgress } from '@mui/material';
const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeContextProvider>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<CircularProgress />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </ThemeContextProvider>
);

reportWebVitals();
