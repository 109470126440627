import React from 'react';
import { FaCode, FaGlobe, FaInternetExplorer, FaPlay, FaSearch } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';
import placeholder from '../../../assets/png/placeholder.png';
import './single-project.css';
import { Link } from 'react-router-dom';

function SingleProjectUI({ id, name, desc, tags, code, demo, image, theme, classes }) {


    return (
        <Fade bottom>
            <div
                key={id}
                className='singleProject'
                style={{ backgroundColor: theme.quaternary }}
            >
                <div className='projectContent'>
                    <h2
                        id={name.replace(' ', '-').toLowerCase()}
                        style={{ color: theme.tertiary }}
                    >
                        {name}
                    </h2>
                    <img src={image ? image : placeholder} alt={name} width={100} style={theme.type === 'light' ? { objectFit: 'contain'} : { objectFit: 'contain', backgroundColor: 'rgb(239, 243, 244)', height:'30%', borderRadius: '5%'}} />
                    <div className='project--showcaseBtn'>
                        <Link
                            to={demo}
                            className={classes.iconBtn}
                            aria-labelledby={`${name
                                .replace(' ', '-')
                                .toLowerCase()} ${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-demo`}
                        >
                            <FaSearch
                                id={`${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-demo`}
                                className={classes.icon}
                                aria-label='Blog posts'
                                style={{cursor: 'pointer'}}
                                title='Encuentra todos los posts del blog impartidos por esta empresa'
                            />
                        </Link>
                        <a
                            href={code}
                            target='_blank'
                            rel='noreferrer'
                            className={classes.iconBtn}
                            aria-labelledby={`${name
                                .replace(' ', '-')
                                .toLowerCase()} ${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-code`}
                        >
                            <FaGlobe
                                id={`${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-code`}
                                className={classes.icon}
                                aria-label='Web'
                                style={{cursor: 'pointer'}}
                                title='Visita la página web de la empresa'
                            />
                        </a>
                    </div>
                </div>
                <p
                    className='project--desc'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {desc}
                </p>
                <div
                    className='project--lang'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {tags.map((tag, id) => (
                        <span key={id}>{tag}</span>
                    ))}
                </div>
            </div>
        </Fade>
    );
}

export default SingleProjectUI;
