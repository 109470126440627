import { Helmet } from "react-helmet";
import blogs from "../../data/blogData";
import { headerData } from "../../data/headerData";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { ThemeContext } from '../../contexts/theme-context';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { skillsData } from "../../data/skillsData";
import { projectsData } from "../../data/projectsData";
import { parseDate } from "../../utils/timeConverter";
import banner from '../../assets/png/banner.png'
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";

function PostPage() {
    const { pathname } = useLocation()
    const [blog, setBlog] = useState({})
    const { theme } = useContext(ThemeContext);
    const [skill, setSkill] = useState({speaker: '', image: ''})
    const [enterprise, setEnterprise] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const id = pathname.split('/')[2]
        if (parseInt(id) > blogs.length || parseInt(id) < 1) {
            navigate('/blog')
        }

        setBlog(blogs.find(blog => blog.id.toString() === id.toString()))
    }
    , [pathname])

    useEffect(() => {
        try {
            setSkill(skillsData[blog.id - 1])
            if (blog.enterprise) setEnterprise(projectsData.find(project => project.projectName.toLowerCase() === blog.enterprise.toLowerCase()))
        } catch (error) {
            console.log(error)
        }
    }, [blog])


    const useStyles = makeStyles((t) => ({
        home: {
            color: theme.secondary,
            position: 'absolute',
            top: 25,
            left: 25,
            padding: '7px',
            borderRadius: '50%',
            boxSizing: 'content-box',
            fontSize: '2rem',
            cursor: 'pointer',
            boxShadow: theme.type === 'dark' ? '3px 3px 6px #ffffff40, -3px -3px 6px #00000050' : '3px 3px 6px #ffffff40, -3px -3px 6px #00000050',
            transition: 'all 0.3s ease-in-out',
            "&:hover":
            {
                color: theme.tertiary,
                transform: 'scale(1.1)',
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
        root: {
            padding: '32px',
            backgroundColor: theme.secondary,
            color: theme.tertiary,
            textAlign: 'justify',
            boxShadow: theme.type === 'dark' ? '3px 3px 6px #00000060, -3px -3px 6px #ffffff10' : '3px 3px 6px #00000030, -3px -3px 6px #ffffffbd',
            "& *":{
                color: theme.tertiary,
            },
            [t.breakpoints.down('md')]: {
                "& * > .imgResults": {
                    width: '100% !important',
                    marginBottom: '30px',
                },
            },
        },
        mobile:{
            [t.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        mobileDiv:{
            [t.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginTop: '20px',
            },
        },
        mobileImg:{
            [t.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px',
            },
        },
        title: {
            [t.breakpoints.down('sm')]: {
                fontSize: '2rem !important',
            },
        },
    }));
    const classes = useStyles();

    const standardStyle = { backgroundColor: theme.primary, backgroundImage: `url(${enterprise?.image})`, backgroundSize: 'contain', backgroundBlendMode: 'contain',  backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }
    const specialStyle = { backgroundColor: theme.primary, backgroundImage: `url(${banner})`, backgroundSize: 'contain', backgroundBlendMode: 'contain',  backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }

    return (
    <div
        className="blogPage"
        style={{ backgroundColor: theme.secondary }}>
        <Helmet>
            <title>{headerData.name} | Post</title>
        </Helmet>
        <div
            className="blogPage--header"
            style={!blog || blog?.id <=10 ? standardStyle : specialStyle}>
            <Link to="/">
            <AiOutlineHome className={classes.home} />
            </Link>
        </div>
        <div className="blogPage--container">
            {
                !blog || blog?.id <= 10 ?
                (<>
                    <Paper className={classes.root} style={{ backgroundColor: theme.quaternary, width: '100%', marginBottom: '30px', display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',}}>
                        <Typography variant='h2' component='h2' className={classes.title} style={{ color: theme.tertiary }}><strong>{blog?.title}</strong></Typography>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '30px',}} className={classes.mobile}>
                            <Typography variant="subtitle1" className={classes.date} style={{color: theme.tertiary}}>
                                Impartida el {parseDate(blog?.published_at)}
                            </Typography>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={classes.mobileDiv}>
                                <Typography variant="subtitle1" style={{color: theme.tertiary,}}>
                                    Ponente: 
                                </Typography>
                                <img src={skill?.image} alt={skill?.speaker} width="50px" style={{marginLeft: '10px', marginRight: '10px', borderRadius: '50%'}} className={classes.mobileImg}/>
                                <Typography variant="subtitle1" style={{color: theme.tertiary}}>
                                    {skill?.speaker}
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper className={classes.root} style={{ backgroundColor: theme.quaternary}}>
                        <Typography variant="h4" component="h4" style={{color: theme.tertiary, marginBottom: '30px'}}>
                            Contexto
                        </Typography>
                        {blog?.context}
                        <Typography variant="h4" component="h4" style={{color: theme.tertiary, marginBottom: '30px'}}>
                            Conceptos aprendidos
                        </Typography>
                        {blog?.concepts}
                        {blog?.questions && (
                        <Typography variant="h4" component="h4" style={{color: theme.tertiary, marginBottom: '30px'}}>
                            Preguntas
                        </Typography>)}
                        {blog?.questions}
                        <Typography variant="h4" component="h4" style={{color: theme.tertiary, marginBottom: '30px'}}>
                            Reflexión y progreso
                        </Typography>
                        {blog?.reflection}
                        <Typography variant="h4" component="h4" style={{color: theme.tertiary, marginBottom: '30px'}}>
                            Ayuda al caso de estudio
                        </Typography>
                        {blog?.help}
                    </Paper>
                </>):
                (<>
                    <Paper className={classes.root}  style={{ backgroundColor: theme.quaternary, width: '100%', marginBottom: '30px', display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',}}>
                        <Typography variant='h2' component='h2' className={classes.title} style={{ color: theme.tertiary }}><strong>{blog?.title}</strong></Typography>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '30px',}} className={classes.mobile}>
                            <Typography variant="subtitle1" className={classes.date} style={{color: theme.tertiary}}>
                                Publicado el {parseDate(blog?.published_at)}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: theme.tertiary,}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={classes.mobileDiv}>
                                    <Typography variant="subtitle1" style={{color: theme.tertiary,}}>
                                        Autor: 
                                    </Typography>
                                    <img src={headerData?.image} alt={headerData?.name} width="50px" height="50px" style={{marginLeft: '10px', marginRight: '10px', borderRadius: '50%', objectFit: 'cover'}} className={classes.mobileImg}/>
                                    <Typography variant="subtitle1" style={{color: theme.tertiary}}>
                                        {headerData?.name}
                                    </Typography>
                                </div>
                            </Typography>
                        </div>
                    </Paper>
                    <Paper className={classes.root} style={{ backgroundColor: theme.quaternary}}>
                        {blog?.content}
                    </Paper>
                </>)
            }
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                {(blog.id-1)>0 && (
                <div className="blog--viewAll" style={{ justifyContent: 'flex-start'}}>
                    <Link to={`/blog/${blog.id-1}`}>
                    <button className={classes.viewAllBtn}>
                        <HiArrowLeft className={classes.viewArr} />
                        Post Anterior
                    </button>
                    </Link>
                </div>
                )}
                {blogs.length >= (blog.id+1) && (
                <div className="blog--viewAll">
                    <Link to={`/blog/${blog.id+1}`}>
                    <button className={classes.viewAllBtn}>
                        Post Siguiente
                        <HiArrowRight className={classes.viewArr} />
                    </button>
                    </Link>
                </div>
                )}
            </div>
        </div>
    </div>
    );
}

export default PostPage;