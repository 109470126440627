import React from 'react'
import { Helmet } from 'react-helmet'
import Blog from '../../components/container/blog'
import Contacts from '../../components/container/contacts'
import Landing from '../../components/container/landing'
import Navbar from '../../components/container/navbar'
import Projects from '../../components/container/projects'
import About from '../../components/core-ui/about/about'
import Skills from '../../components/core-ui/skills/skills'
import { headerData } from '../../data/headerData'
import { Typography } from '@mui/material'

function HomePage() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} | Porfolio</title>
            </Helmet>
            <Navbar />
            <Landing />
            <About />
            <Skills />
            <Projects />
            <Blog />
            <Contacts />
            <Typography variant='body1' align='center' style={{display: 'flex', justifyContent: 'center'}}>
                &copy; {new Date().getFullYear()} {headerData.name}
            </Typography>
        </div>
    )
}

export default HomePage
