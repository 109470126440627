import { Avatar, Typography } from '@mui/material';
import antonio from '../assets/blog/antonio.jpeg';
import first from '../assets/blog/1.png';
import second from '../assets/blog/2.png';
import third from '../assets/blog/3.png';
import fourth from '../assets/blog/4.png';
import fifth from '../assets/blog/5.png';
import sixth from '../assets/blog/6.png';
import seventh from '../assets/blog/7.png';
import eighth from '../assets/blog/8.png';
import ninth from '../assets/blog/9.png';
import tenth from '../assets/blog/10.png';
import eleventh from '../assets/blog/11.png';
import twelfth from '../assets/blog/12.png';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import excel from '../assets/excel/Rúbrica DP.xlsx';
import resultados from '../assets/png/resultados.png';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const blogs = [{
    id: 1,
    title: 'Inicio del Proyecto',
    description: 'La sesión comenzó con una visión general de la asignatura. Durante la presentación se abordaron los siguientes apartados...',
    published_at: 'December 11, 2023',
    cover_image: first,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext1(),
    concepts: getConcepts1(),
    reflection: getReflection1(),
    help: getHelp1(),
},
{
    id: 2,
    title: 'Metodologías de Gestión',
    description: 'La sesión versó sobre la necesidad de adoptar una metodología para los proyectos y se presentaron los principales enfoques actuales: ITIL 4, Lean, metodologías ágiles, y DevOps...',
    published_at: 'December 12, 2023',
    cover_image: second,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext2(),
    concepts: getConcepts2(),
    reflection: getReflection2(),
    help: getHelp2(),
},
{
    id: 3,
    title: 'Toma de Requisitos, Análisis y Diseño de un SI',
    description: 'La sesión presentó una visión exhaustiva sobre la gestión de proyectos tecnológicos, enfocándose en aspectos clave como la toma de requisitos, análisis y diseño de sistemas de información...',
    published_at: 'December 18, 2023',
    cover_image: third,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext3(),
    concepts: getConcepts3(),
    reflection: getReflection3(),
    help: getHelp3(),
},
{
    id: 4,
    title: 'Construcción de un SI',
    description: 'La sesión fue enfocada en la construcción de sistemas de información (SI) utilizando diferentes metodologías y arquitecturas. A continuación, se proporciona un breve resumen del contenido de la misma...',
    published_at: 'December 19, 2023',
    cover_image: fourth,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext4(),
    concepts: getConcepts4(),
    reflection: getReflection4(),
    help: getHelp4(),
},
{
    id: 5,
    title: 'Implantación y Mantenimiento del Sistema',
    description: 'En la sesión se detallaron múltiples aspectos relacionados a la implantación y el mantenimiento de sistemas de información. A continuación, se proporciona un resumen de dicho contenido...',
    published_at: 'December 20, 2023',
    cover_image: fifth,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext5(),
    concepts: getConcepts5(),
    reflection: getReflection5(),
    help: getHelp5(),
},
{
    id: 6,
    title: 'Aseguramiento y Control de la Calidad',
    description: 'En la sesión se abordaron los aspectos esenciales del aseguramiento y control de la calidad en la gestión de proyectos TI. El contenido presentado fue el siguiente...',
    published_at: 'December 21, 2023',
    cover_image: sixth,
    read: 3,
    enterprise: 'Ayesa',
    context: getContext6(),
    concepts: getConcepts6(),
    reflection: getReflection6(),
    help: getHelp6(),
},
{
    id: 7,
    title: 'Dirección de Proyectos en el Contexto Multinacional',
    description: 'En el marco de un desafiante proyecto internacional, se presentó una iniciativa compleja en la que intervino el ponente, en calidad de responsable de proyecto...',
    published_at: 'January 9, 2024',
    cover_image: seventh,
    read: 4,
    enterprise: 'Ayesa',
    context: getContext7(),
    concepts: getConcepts7(),
    questions: getQuestions7(),
    reflection: getReflection7(),
    help: getHelp7(),
},
{
    id: 8,
    title: 'Dirección de Proyectos en Fases Embrionarias',
    description: 'La ponencia que se llevó a cabo abordó de manera exhaustiva la concepción y gestión de proyectos, proporcionando una visión profunda y detallada de los elementos clave para su éxito...',
    published_at: 'January 10, 2024',
    cover_image: eighth,
    read: 4,
    enterprise: 'IaaS365',
    context: getContext8(),
    concepts: getConcepts8(),
    questions: getQuestions8(),
    reflection: getReflection8(),
    help: getHelp8(),
},
{
    id: 9,
    title: 'Transformación Digital en la Dirección de Proyectos',
    description: 'Durante la charla, el ponente ofreció una visión integral sobre la transformación digital en la dirección de proyectos, destacando varias características y principios clave...',
    published_at: 'January 11, 2024',
    cover_image: ninth,
    read: 4,
    enterprise: 'Deloitte',
    context: getContext9(),
    concepts: getConcepts9(),
    questions: getQuestions9(),
    reflection: getReflection9(),
    help: getHelp9(),
},
{
    id: 10,
    title: 'Dirección de Proyectos en Salud (SAS)',
    description: 'La charla se centró en la gestión de proyectos en el sector de la salud, proporcionando insights valiosos sobre cómo las grandes empresas han triunfado al alinear sus requisitos de producto con las expectativas de los clientes...',
    published_at: 'January 15, 2024',
    cover_image: tenth,
    read: 5,
    enterprise: 'Grupo Solutia',
    context: getContext10(),
    concepts: getConcepts10(),
    questions: getQuestions10(),
    reflection: getReflection10(),
    help: getHelp10(),
},
{
    id: 11,
    title: 'Autoevaluación Final',
    description: 'En esta sección, se lleva a cabo una reflexión crítica y una autoevaluación, estructurada según la taxonomía de Bloom, sobre los conocimientos y competencias desarrolladas a lo largo del curso en la asignatura de Dirección de Proyectos...',
    published_at: 'January 18, 2024',
    cover_image: eleventh,
    read: 2,
    content: getContent11(),
},
{
    id: 12,
    title: 'Reflexiones Finales',
    description: 'Este capítulo final de reflexiones tiene como objetivo consolidar y sintetizar los aprendizajes y experiencias obtenidos a lo largo de las sesiones y conferencias de la asignatura de Dirección de Proyectos...',
    published_at: 'January 18, 2024',
    cover_image: twelfth,
    read: 3,
    content: getContent12()
},
]

function getContent12(){    
    return(
        <>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Introducción
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Este capítulo final de reflexiones tiene como objetivo consolidar y sintetizar los aprendizajes y experiencias obtenidos a lo largo de las sesiones y conferencias de la asignatura de Dirección de Proyectos. A través de una introspección cuidadosa, se busca extraer lecciones valiosas y perspectivas fundamentales para cualquier profesional en el ámbito de la gestión de proyectos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Además, se aprovechará para valorar el portfolio del resto de compañeros (y el propio) extrayendo los aspectos destacables de los mismos. 
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Reflexiones Iniciales
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La gestión de proyectos, como se ha evidenciado en los capítulos anteriores, es una disciplina multifacética que requiere una comprensión profunda de diversos aspectos, desde la teoría hasta la aplicación práctica en diferentes contextos. A lo largo de las sesiones del curso, se ha enfatizado la importancia de un enfoque holístico que considera desde las etapas iniciales de un proyecto hasta su implantación y mantenimiento.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Una de las lecciones más significativas obtenidas es la relevancia de adaptar las metodologías y estrategias de gestión a las características únicas de cada proyecto. La flexibilidad y capacidad de adaptación son habilidades cruciales para un gestor de proyectos eficiente, tal como se reflejó en las distintas sesiones. La integración de teoría y práctica, mediante el estudio de casos reales y la experiencia compartida por expertos, ha proporcionado una base sólida para comprender mejor cada fase del ciclo de vida de un proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Además, la experiencia práctica y la participación en el caso práctico de Ayesa han sido fundamentales para la aplicación de los conocimientos adquiridos. Este enfoque práctico ha permitido no solo comprender teóricamente los conceptos, sino también experimentar de primera mano los desafíos y recompensas que conlleva la gestión de proyectos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Las conferencias invitadas, por otro lado, han enriquecido el entendimiento del campo desde distintas perspectivas, proporcionando una visión amplia de la gestión de proyectos en sectores tan diversos como la salud, el ámbito multinacional y la transformación digital. Estas charlas han subrayado la importancia de estar al día con las tendencias actuales y de desarrollar un enfoque que combine habilidades técnicas con una comprensión del contexto más amplio en el que se desarrollan los proyectos. Personalmente, considero estas charlas como el mayor diferenciador de esta asignatura frente a otras y el corazón de la misma. Ha sido una grata sorpresa encontrarme con esta magnífica dinámica.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En resumen, con este capítulo de reflexiones finales se busca destacar la importancia del aprendizaje continuo, la adaptabilidad y la integración de diversas perspectivas en la gestión eficaz de proyectos. La experiencia acumulada a lo largo de este curso no solo sirve como una base para futuros proyectos, sino que también forma a un director de proyectos más completo, capaz de enfrentar los desafíos de un mundo en constante cambio.
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Valoración de los portfolios
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En esta sección realizaré una valoración de los portfolios de mis compañeros y concluiré con una evaluación sobre mi propio portfolio.
            </Typography>
            <Typography variant="h5" component="h5" style={{marginBottom: '20px'}}>
                ¿Qué he introducido?
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Considero que varios de los portfolios de los compañeros incluían los siguientes apartados que he decidido incluir en mi portfolio:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Imágenes: Los portfolios de Gabriel López Bellido y de Juan María incluían imágenes en alguna sección de su portfolio, por lo que he decidido imitarlos.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Tablas: para representar la información en los portfolios de Juan Noguerol Tirado, José Manuel Sánchez Ruíz, Víctor Manuel Vázquez García, Javier Vázquez Zambrano y Rocío Vecino Torres se han usado tablas.</Typography></li>
            </ul>
            <Typography variant="h5" component="h5" style={{marginBottom: '20px'}}>
                ¿Qué me ha encantado?
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Los 5 aspectos más valiosos que me llevo de los portfolios de mis compañeros son:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>De Javier Terroba Orozco, me ha encantado la autoevaluación final que he visto muy completa. De hecho, gracias a su aviso, he modificado la mía en esta versión para que incluyese todo lo que se exigía.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>De José Manuel Sánchez Ruíz, me ha parecido interesante que incluyese una tabla de control de versiones, por lo que he optado por hacer lo mismo.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>De David Barragán Salazar, me han gustado las preguntas que ha incluido en su portfolio dado que han sido numerosas y recuerdo lo acertadas que me parecieron cuando las realizó en clase.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>De Youssef Lakouifat Darkaoui, me ha parecido acertado que ha redactado las sesiones sin necesidad de utilizar listas o tablas.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>De Ángela Bernal, me han sorprendido los apartados de reflexión y progreso y de ayuda al caso de estudio, dado que estaban más desarrollados que los de la media.</Typography></li>
            </ul>
            <Typography variant="h5" component="h5" style={{marginBottom: '20px'}}>
                Mis directores de proyecto
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Actualmente, teniendo en cuenta las notas que aparecen en la rúbrica (se adjunta más abajo) y que reflejan el trabajo y esfuerzo de estos compañeros en la asignatura, elegiría como mis directores de proyecto a Javier Terroba Orozco y a David Barragán Salazar. Además, como he tenido la oportunidad tanto en el máster como en el grado de trabajar con ambos, considero que están muy capacitados de desempeñar exitosamente esta labor.
            </Typography>
            <Typography variant="h5" component="h5" style={{marginBottom: '20px'}}>
                Calificación de los portfolios
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Para evaluar los portfolios me he basado en la rúbrica proporcionada por los profesores para adaptarla y que permita evaluar los portfolios beta del resto de companeros. Para empezar eso significará tener que evaluar 4 campos con el mismo peso (25% cada uno):
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Entrega</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Alcance</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Completitud</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Calidad</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Además, para poder evaluar la originalidad y la innovación de alguno de los portfolios se ha definido un área extra que permite que la nota ascienda hasta 12 puntos (20% de incremento). Por supuesto a la hora de calcular la nota final se truncará todo lo que pase de 10.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Entrega
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Para este campo se evalua que la entrega de la beta del portfolio se haya llevado a cabo. En caso negativo, se reduciría la rúbrica (aunque no ha sido necesario). En la tabla de la rúbrica que se puede descargar <a
                                    href={excel}
                                    download='Rúbrica - Francisco Javier Cavero López.xlsx'
                                    style={{backgroundColor: '#FFFF00', color: '#000000', textDecoration: 'underline'}}
                                >aquí</a> se pueden apreciar las puntuaciones de este apartado.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Alcance
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Este apartado evalúa el número de sesiones y de conferencias que han sido tratadas en el portfolio. Para ello se definió la siguiente escala:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"6 sesiones y 4 conferencias -> 10"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"5 sesiones y 4 conferencias -> 9"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"4 sesiones y 4 conferencias -> 8"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"3 sesiones y 4 conferencias -> 7"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"2 sesiones y 4 conferencias -> 6"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"1 sesión y 4 conferencias -> 5"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"La suma de sesiones y conferencias desarrolladas es igual a 4 -> 4"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"La suma de sesiones y conferencias desarrolladas es igual a 3 -> 3"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"La suma de sesiones y conferencias desarrolladas es igual a 2 -> 2"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"La suma de sesiones y conferencias desarrolladas es igual a 1 -> 1"}</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>{"0 sesiones y 0 conferencias -> 0"}</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Tras aplicarla se obtienen los resultados reflejados en la rúbrica descargable.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Completitud
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                La completitud versa sobre la cantidad de apartados y secciones que contienen los portfolios.
                Los subapartados evaluados serán:
            </Typography>
            <ol>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Conceptos aprendidos</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Autoevaluación final</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Reflexión y progreso</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Preguntas realizadas y reflexión final</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Ayuda a la solución</Typography></li>
            </ol>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Cada uno de ellos constituye hasta un 20% de la nota del apartado. En el caso del subapartado de preguntas realizadas y reflexión final, se repartirá la puntuación en partes iguales (10% para las preguntas y 10% para la reflexión final). Es importante señalar, que se han ordenado los subapartados a evaluar para que si en uno de ellos se obtiene un 0, en los sucesivos no se tenga en cuenta el valor de los siguientes (esta medida no ha necesitado llegar a emplearse).
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                La nota de este apartado oscilará entre los siguientes valores:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>10 para representar que el apartado está completo.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>7.5 para representar que uno de los apartados está completo y el otro no (aplicable sólo en la columna de preguntas realizadas y reflexión final).</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>5 para representar que el apartado está incompleto. En el caso de la columna de preguntas realizadas y reflexión final, este valor puede representar o que uno de los dos apartados está completo, o que ambos están incompletos.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>2.5 para representar que uno de los apartados está completo y el otro es inexistente o no es válido (aplicable sólo en la columna de preguntas realizadas y reflexión final).</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>0 para representar que el apartado es inexistente o no es válido.</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Teniendo en cuenta esta información, los valores finales de este apartado son los de la rúbrica anteriormente mencionada.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Calidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Para evaluar la calidad de los apartados he utilizado los siguientes indicadores:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Calidad en el formato del documento</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Calidad en la redacción y ortografía</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Profundiza en los siguientes apartados: sesiones, conferencias, preguntas, conceptos, reflexión y progreso y ayuda.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Profundiza en la autoevaluación y reflexión final</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Calidad en las conclusiones, hay definido un hilo argumental en el documento y no aparecen incongruencias graves.</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Estos indicadores se evaluán primordialmente siguiendo la escala:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>10 para indicar que se sobresale en ese aspecto</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>5 para indicar que hay carencias en ese aspecto</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>0 para indicar qeue no se alcanza un nivel aceptable en ese aspecto</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                No obstante, se introducen los valores 2.5 y 7.5 para representar los casos en los que las carencias sean muy deficientes o lo suficientemente superficiales para no tener que llegar a los extremos. Los valores finales de este campo se localizan en la rúbrica mencionada.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Originalidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Como se ha mencionado anteriormente, para reflejar los elementos innovadores introducidos en los portfolios se definen los siguientes apartados (cada uno de ellos se pondera sobre 0,5, es decir, un 25% de los 2 puntos extra):
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Innovación/Creatividad: evalúa si se realiza cualquier tipo de innovación sobre el portfolio que no se haya indicado de antemano.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Añade apartados o secciones: evalúa si el portfolio incluye apartados o secciones que no han sido demandadas por los profesores (como incluía el trabajo de ejemplo que nos mostraron).</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Usa contendio multimedia: evalúa si el portfolio incluye imágenes, gráficas o diagramas (como incluía el trabajo de ejemplo que nos mostraron).</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>Es el único que lo hace: evalúa que el elemento innovador sea novedoso y único.</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                La escala para evaluar estos apartados es la siguiente:
            </Typography>
            <ul>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>10 si el elemento introducido se hace de forma integral sobre el portfolio.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>5 si la integración es simplemente parcial.</Typography></li>
                <li><Typography variant="body1" style={{marginBottom: '20px'}}>0 si no se ha innovado para realizar el portfolio.</Typography></li>
            </ul>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Los resultados de este apartado se localizan en la rúbrica que se adjunta.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Resultados
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Teniendo en cuenta todos los aspectos desarrollados anteriormente, las calificaciones de los portfolios son las reflejadas en la rúbrica.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Como vemos, todas estas notas han acabado oscilando entre el 6 y el 10 como los profesores de la asignatura predijeron. Facilito el siguiente gráfico para representar visualmente las notas finales de los portfolios de mis compañeros.
            </Typography>
            <div style={{alignContent: 'center', justifyContent: 'center', display: 'flex'}} ><img className="imgResults" src={resultados} alt="Gráfico de barras con las notas de los portfolios de mis compañeros" style={{width: '50%'}}/></div>
            <Typography variant="h5" component="h5" style={{marginBottom: '20px'}}>
                Calificación de mi portfolio
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Para calificar mi portfolio he utilizado la misma rúbrica. Así, la calificación de mis apartados quedaría como aparece en los subapartados de esta sección.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Entrega
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Al entregar en tiempo, mi nota de este apartado es un 10.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Alcance
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Como he incluido todas las sesiones y conferencias, tengo los 2.5 puntos de este apartado.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Completitud
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                El portfolio incluye todos los apartados que se pedían y aunque el portfolio beta alcanzó un 9, la versión definitiva ha alcanzado un 10.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Calidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Durante la fase beta solo se alcanzó un 7 en este apartado. Sin embargo, gracias a la versión definitiva, la nota final es un 9.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Originalidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Durante la fase beta, solo tenía un 5 en este aspecto (la web aún no estaba terminada y no incluía fotos). Ahora, con las correcciones, la nota se ha elevado hasta alcanzar un 8,75.
            </Typography>
            <Typography variant="h6" component="h6" style={{marginBottom: '20px'}}>
                Resultados
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Todas estas notas, cuando se aplican las fórmulas definidas anteriormente, dan un total de <strong>10</strong>. Concretamente, un 10 cuando se le añade el extra en la versión beta (9 + 1) y un 11.5 (9.75 + 1.75) cuando se le añade el extra a la nota sin restringir de la versión definitiva. Creo que esta nota es totalmente acorde al esfuerzo y dedicación puestos en la asignatura y que este portfolio y las actividades relacionadas así lo han demostrado.
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Conclusiones
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                De la asignatura Dirección de Proyectos se extraen, entre otras, las conclusiones que se desarrollan a continuación.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Primero, la combinación de teoría y práctica emerge como un pilar fundamental en la formación en gestión de proyectos. Las sesiones y conferencias del curso han demostrado que entender y aplicar los conceptos teóricos en contextos reales es esencial para una comprensión profunda del ciclo de vida de los proyectos. Esta integración permite a los profesionales adaptar métodos y estrategias a las particularidades de cada proyecto, enfatizando la relevancia de la flexibilidad y adaptabilidad en este campo.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Además, la asignatura ha resaltado la importancia del aprendizaje continuo y la adaptabilidad. En un mundo donde los desafíos y contextos de los proyectos están en constante evolución, mantenerse actualizado y ser capaz de ajustarse a nuevas situaciones es crucial. Esto no solo es importante para la resolución de problemas inmediatos, sino también para la preparación a largo plazo de los futuros directores de proyectos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La diversidad de perspectivas presentadas a través de las conferencias y la interacción con compañeros de curso ha enriquecido la experiencia de aprendizaje. La gestión de proyectos se beneficia enormemente de un enfoque holístico que integra distintos puntos de vista y conocimientos, preparando a los profesionales para operar eficazmente en una variedad de sectores y contextos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Otro aspecto destacado es la valoración y mejora continua del trabajo propio y de los demás. La evaluación de los portfolios, tanto propios como de compañeros, se convierte en una herramienta vital para el autoaprendizaje y el desarrollo profesional. Al reflexionar críticamente sobre estas obras, se identifican áreas de fortaleza y oportunidades para mejorar, fomentando un ciclo continuo de aprendizaje y perfeccionamiento.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La calidad, originalidad y completitud son aspectos claves en la evaluación de los portfolios. Estos criterios no solo reflejan la comprensión y aplicación del material del curso, sino que también demuestran la habilidad para innovar y aplicar estos conocimientos de manera integral. Estos elementos son fundamentales para demostrar una comprensión profunda y práctica de la gestión de proyectos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Por último, esta asignatura nos ha equipado con las herramientas y conocimientos necesarios para enfrentar los desafíos del mundo real en la gestión de proyectos. La combinación de conocimientos teóricos, habilidades prácticas y reflexión crítica proporciona una base sólida para cualquier futuro director de proyectos, destacando la importancia de estar preparados para un entorno profesional en constante cambio.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En conclusión, esta asignatura ha sido una experiencia enriquecedora que nos ha preparado para ser directores de proyectos efectivos y adaptables. La importancia del aprendizaje continuo, la adaptabilidad y la capacidad de integrar diversas perspectivas en la gestión de proyectos ha quedado claramente demostrada a lo largo del programa.
            </Typography>
        </>
    )
}

function getContent11(){
    return(
        <>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Introducción
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En esta sección, se lleva a cabo una reflexión crítica y una autoevaluación, estructurada según la taxonomía de Bloom, sobre los conocimientos y competencias desarrolladas a lo largo del curso en la asignatura de Dirección de Proyectos. Se examina cómo se han integrado y aplicado estos conocimientos en contextos prácticos y estudios de caso, destacando el desarrollo cognitivo alcanzado a través del curso.
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Conceptos Aprendidos (Recordar y Comprender)
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Se ha consolidado la base de conocimientos en gestión de proyectos, recordando y comprendiendo conceptos fundamentales. Esto incluye la identificación y comprensión de diversas metodologías de gestión, como las ágiles y tradicionales, y el reconocimiento de la importancia de la calidad y la sostenibilidad en los proyectos. Se ha logrado también una comprensión clara de la relevancia de la transformación digital y la adaptabilidad en diversos contextos como la salud y la gestión multinacional. Por último, se destaca el entendimiento de alcanzar un equilibrio entre las habilidades técnicas y de negocios necesarias para alcanzar una dirección eficaz de proyectos.
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Competencias Desarrolladas (Aplicar y Analizar)
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Las habilidades desarrolladas se centran en la aplicación y análisis de los conceptos aprendidos. Se ha aplicado activamente el conocimiento en situaciones reales, utilizando metodologías adecuadas en casos de estudio específicos. Además, se ha analizado críticamente los diferentes elementos de la gestión de proyectos, evaluando su efectividad y relevancia en distintos contextos. Esto incluye la capacidad para desglosar proyectos complejos y discernir los componentes críticos para una gestión eficaz.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Ahora me siento competente, por tanto, para enfrentarme a la gestión de un proyecto que se ha desviado del cronograma y tengo los conocimientos necesarios para poder dirigir exitosamente un proyecto en cualquiera de sus fases. También, considero que puedo asistir en la implementación efectiva de la transformación digital de una empresa. No obstante, respecto a lo explicado en las últimas conferencias sobre los procesos para tener éxito en un concurso público, necesitaría realizar un pequeño proyecto en el que se llevase a cabo dichas actividades para terminar de adquirir las habilidades necesarias en ese campo.
            </Typography>
            <Typography variant="h4" component="h4" style={{marginBottom: '20px'}}>
                Conclusiones (Evaluar y Crear)
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Las sesiones y conferencias han proporcionado un entendimiento integral y multifacético de la dirección de proyectos. En conclusión, se destaca la importancia de la evaluación continua y la adaptación en la gestión de proyectos, así como la habilidad para innovar y crear soluciones personalizadas para desafíos específicos. Este nivel de comprensión demuestra no solo la adquisición de conocimientos, sino también la capacidad de utilizar estos conocimientos de manera efectiva y creativa en el ámbito profesional. La integración de la teoría con la práctica y el aprendizaje continuo emergen como elementos cruciales para el crecimiento y desarrollo en el campo de la gestión de proyectos.
            </Typography>
        </>
    )
}

function getContext10() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla se centró en la gestión de proyectos en el sector de la salud, proporcionando insights valiosos sobre cómo las grandes empresas han triunfado al alinear sus requisitos de producto con las expectativas de los clientes. A continuación, se presenta un resumen ampliado y detallado de los puntos clave discutidos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Enfoque en Requisitos de Producto y Expectativas del Cliente
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente resaltó que el éxito de las grandes empresas radica en su capacidad para hacer que sus requisitos de producto coincidan con lo que los clientes esperan. Puso como ejemplo anecdótico la habilidad de los bancos para hacerse pasar por clientes y colocar al proveedor en una posición de servicio.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                El Papel de la Tecnología y la Innovación
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enfatizó que, aunque el software es una herramienta crucial, son las personas las que realmente cambian el mundo. La tecnología debe ser vista como un medio para alcanzar un fin, no el fin en sí mismo. Se destacó la importancia de la humildad en los perfiles técnicos y de gestión y de enfocar las innovaciones hacia el éxito del proyecto, utilizando los recursos mínimos necesarios.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Importancia de la Formación Continua y Orientación al Negocio
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La formación continua fue identificada como un factor clave para el cambio personal y profesional, aunque con expectativas realistas. Se reforzó la idea de que la visión y el modelo de negocio deben prevalecer sobre la tecnología.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Equilibrio entre Negocio y Tecnología
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente subrayó la necesidad de un perfil equilibrado entre los aspectos comerciales y tecnológicos, mostrando una distribución gaussiana para ilustrar la diferencia entre estos perfiles.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Comparación entre los Sectores Público y Privado
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se señaló que los proyectos públicos suelen demorar entre 15 y 18 meses desde su concepción hasta su inicio debido a procesos burocráticos, a diferencia de los proyectos privados que pueden comenzar de inmediato. Sin embargo, se mencionó que trabajar en el sector público puede ser más gratificante debido a su impacto en la sociedad.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Licitaciones y Contratos en el Sector Público
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente reflexionó sobre la complejidad de redactar pliegos de licitación, equilibrando la definición precisa de requisitos con la necesidad de no ser excesivamente restrictivos. Se destacó la importancia de la gobernanza para controlar la definición del proyecto y evitar que los proveedores tomen el control.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Diferencias Salariales y de Personal en los Sectores Público y Privado
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se observó que el sector privado tiende a tener menos empleados pero con salarios más altos, mientras que el público cuenta con más personal pero con salarios más bajos. El ponente sugirió que podría ser más rentable contratar a personas con salarios más altos pero más eficientes.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Desafíos de Satisfacer la Demanda y Definición de Actores Clave
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Registrar todos los detalles de la demanda es un reto significativo, y se destacó la importancia de definir quiénes son los actores clave en la toma de decisiones.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Características de un Buen Departamento de Desarrollo de Software en la Administración Pública
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente mencionó tres características esenciales para un departamento eficaz:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Adecuación a las Necesidades Reales:</strong> Enfocarse en lo que realmente se necesita.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Calidad y Sostenibilidad:</strong> Mantener estándares elevados y asegurar la viabilidad a largo plazo.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Time To Market:</strong> La rapidez en la entrega y respuesta al mercado.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Roles Científicos y Gestión en la Administración Pública
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se comentó que las personas con roles más científicos tienen mayor facilidad para desempeñarse en puestos administrativos, de dirección o gestión.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Implementación de Metodologías Ágiles en el Sector Público
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente abogó por la implementación de metodologías ágiles en la Administración Pública para mejorar la capacidad de reacción, adaptándolas según sea necesario y no aplicándolas de manera rígida.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Costo de la Metodología Ágil
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se concluyó que, según la experiencia del ponente, la metodología ágil no resulta más costosa que las metodologías tradicionales, siempre y cuando se implemente correctamente.
            </Typography>
            <Typography variant='body1' style={{marginBottom: '30px'}}>
                La charla ofreció una visión completa sobre la dirección de proyectos en el sector de la salud, resaltando la importancia de un enfoque equilibrado entre negocio y tecnología, y la necesidad de adaptar las metodologías y procesos a las particularidades del sector público.
            </Typography>
        </>
    )
}

function getConcepts10() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla me proporcionó una comprensión más profunda de cómo funciona la Administración Pública y los factores cruciales para el éxito de un proyecto en este ámbito. Además, ahora poseo un conocimiento más claro de las características ideales para gestionar un departamento de software en la administración, así como una serie de consejos valiosos que podré aplicar en mi trayectoria profesional.
            </Typography>
        </>
    )
}

function getQuestions10() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                En primer lugar le pregunté: "¿En qué sector fracasan más proyectos?"
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Su respuesta fue la siguiente: "Los proyectos, en el sector privado, pueden reconducirse y al tener un 'presupuesto ilimitado' si fuese necesario, pueden hacer triunfar proyectos avocados al fracaso. La pública, aunque la gente suele ser más voluntariosa no pueden competir en esas situaciones. Los proyectos públicos no tienen mucho margen de maniobra y suelen tener presupuestos mucho menores".
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Mi segunda pregunta fue: "¿Cómo se enfrentaría al desafío de preparar perfiles más equilibrados en sus conocimientos negocio-técnicos?"
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La respuesta del ponente a esta pregunta fue: "Lo más realista es que sea la empresa quién realice una formación de esos tipos de perfiles. No obstante, lo idóneo sería que desde los programas de ingeniería de las universidades se realizase".
            </Typography>
        </>
    )
}

function getReflection10() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Aunque la duración de la charla me pareció corta, el contenido fue extremadamente valioso. Los consejos proporcionados son herramientas útiles que me ayudarán a abordar desafíos similares en el futuro. Este conocimiento me permitirá mantener una perspectiva más amplia y clara del proceso de gestión de proyectos, tanto en la Administración Pública como en el Sector Privado.
            </Typography>
        </>
    )
}

function getHelp10() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La conferencia ha sido útil para evaluar los factores que contribuyen al éxito de un proyecto. Esta comprensión es beneficiosa al analizar casos prácticos, permitiéndome aplicar estos conocimientos para identificar y evaluar aspectos clave que pueden influir en el éxito o fracaso de un proyecto. Estos aprendizajes me proporcionan una base sólida para una evaluación más efectiva y completa en futuras situaciones prácticas.
            </Typography>
        </>
    )
}

function getTooltip() {
    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Avatar alt="Antonio Ruíz" src={antonio} style={{marginRight: '10px', width: '100px', height: '100px'}}/>
            <Typography variant="body1" style={{marginBottom: '20px', marginTop: '20px'}}>
                El profesor Antonio Ruíz Cortés es catedrático del departamento Lenguajes y Sistemas Informáticos en la Universidad de Sevilla, dónde imparte clases en el Máster en Ingeniería del Software y coordina la asignatura Dirección de Proyectos.
            </Typography>
        </div>
    )
}

function getContext9() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Durante la charla, el ponente ofreció una visión integral sobre la transformación digital en la dirección de proyectos, destacando varias características y principios clave. A continuación, se presenta un resumen enriquecido y detallado de los aspectos más relevantes abordados.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Ritmo de Adopción Tecnológica y Transformación de Productos a Ecosistemas
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se destacó cómo la tecnología avanza a un ritmo mucho más rápido que la capacidad de adopción tanto de las empresas como de la administración pública. El ponente señaló una transición notable de productos tradicionales a ecosistemas y plataformas digitales, lo que está generando una competencia intensa entre estos nuevos modelos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Mentalidad "Peppa" y Principios de Innovación
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El concepto de "mentalidad Peppa", inspirado en el personaje de "Peppa Pig", se refiere a la necesidad de invertir esfuerzo y pasión para alcanzar los sueños. Se subrayó la importancia de tener claridad en los objetivos para impulsar la innovación, destacando que son las personas las que aportan valor diferencial en este proceso.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Tecnologías Emergentes en la Industria 4.0
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se identificaron tres tecnologías clave que definen la Industria 4.0:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Inteligencia Artificial (IA):</strong> Capacidad de <strong>pensar</strong> y analizar datos.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Blockchain:</strong> Funciona como un registro inmutable que <strong>recuerda</strong>.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Internet de las Cosas (IoT):</strong> Permite que los objetos <strong>sientan</strong> y recolecten datos.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Business Model Canvas y Metodologías Ágiles
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se presentó el Business Model Canvas como una herramienta eficaz para definir de manera sencilla el modelo de negocio. También se destacó el concepto de "pivotar" en los negocios, refiriéndose a la adaptación o cambio de modelos de negocio, con énfasis en la entrega de valor constante y continua, preferentemente mediante metodologías ágiles.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                El Triángulo de Negocio y el Círculo de Oro
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se explicó que un negocio con una propuesta de valor clara, un modelo de ingresos bien definido, una cadena de valor efectiva y un segmento objetivo adecuado, tiene altas probabilidades de éxito. Además, se presentó la filosofía del círculo de oro, que prioriza el "porqué" sobre el "cómo" y el "qué" dentro del ámbito de los negocios.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Innovación en Modelos de Negocio
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente enfatizó la necesidad de innovar más en el modelo de negocio que en los procesos o en la tecnología. Además, se mencionó la importancia de crear espacios adecuados para las personas, aspecto fundamental en las metodologías Lean y Agile.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Impacto de la Pandemia y Diferenciación entre Digitalización y Transformación Digital
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se destacó cómo la pandemia ha acelerado el proceso de transformación digital, aunque muchas PYMES han confundido este concepto con la mera digitalización de herramientas. El profesor <CustomWidthTooltip arrow title={getTooltip()}><span style={{backgroundColor: '#FFFF00', color: '#000000'}}>Antonio Ruíz</span></CustomWidthTooltip> acuñó el término “tortura digital” para describir la adopción de tecnologías digitales sin una adecuada adaptación de los procesos y modelos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Innovación Sostenida vs. Disruptiva
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se mencionó que los líderes de mercado suelen adoptar una innovación sostenida, con cambios progresivos, mientras que los nuevos entrantes optan por tecnologías disruptivas para lograr un impacto significativo en el mercado.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Priorización de Modelos de Negocio y Sistemas de Información Empresariales
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla enfatizó la importancia de priorizar los modelos de negocio sobre los productos y la relevancia de los sistemas de información empresariales en la generación de sabiduría y predicción de resultados.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Cadena de Valor y Cuadro de Mando Integral
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se explicó la necesidad de optimizar la cadena de valor para que sea corta y rápida, utilizando la metodología PPS (Personas, Procesos, Sistemas). Además, se habló sobre la importancia del cuadro de mando integral, que integra la visión y misión de la compañía, temas estratégicos, resultados del cliente y la representación de objetivos en un mapa estratégico.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla concluyó con un mensaje inspirador: “Haz lo que amas y ama lo que haces”, instando a los participantes a seguir su pasión en sus emprendimientos y proyectos.
            </Typography>
        </>
    )
}

function getConcepts9() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla ha sido una fuente de aprendizajes novedosos y fundamentales. Uno de los aspectos más destacados es la importancia del Modelo de Negocio y su vinculación con la innovación para crear negocios exitosos. Además, esta charla ha sido esencial para comprender la importancia de ver la tecnología como un medio para alcanzar un fin, más que un fin en sí misma. El concepto de Transformación Digital, especialmente relevante desde la pandemia, fue explicado de manera clara, diferenciándolo de la simple digitalización. Este entendimiento me capacita para asistir en la implementación efectiva de la Transformación Digital en las empresas.
            </Typography>
        </>
    )
}

function getQuestions9() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Al ponente le pregunté: "¿Cuál es el mayor reto de la transformación digital?"
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Su respuesta fue: "La gestión del cambio es el mayor reto de la transformación digital. Hay que educar a las personas en el uso de estas nuevas tecnologías. La tecnología ya existe, falta el conocimiento y el hábito de aplicarlas".
            </Typography>
        </>
    )
}

function getReflection9() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La charla proporcionó reflexiones profundas y pertinentes. La actualidad del contenido y la inclusión de conceptos no tradicionalmente abordados en programas de ingeniería añadieron un valor significativo. La reflexión final del ponente sobre hacer lo que amas y amar lo que haces, en conexión con el concepto japonés de ikigai, ha sido particularmente inspiradora, promoviendo un enfoque holístico y equilibrado hacia la vida profesional y personal.
            </Typography>
        </>
    )
}

function getHelp9() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Aunque no encontré una conexión directa entre los contenidos de esta charla y el caso práctico específico, los conocimientos adquiridos son ampliamente aplicables en diversos contextos profesionales. La comprensión de la Transformación Digital y la importancia del Modelo de Negocio son herramientas valiosas que pueden ser beneficiosas en una variedad de situaciones de gestión y desarrollo de proyectos.
            </Typography>
        </>
    )
}

function getContext8() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La ponencia que se llevó a cabo abordó de manera exhaustiva la concepción y gestión de proyectos, proporcionando una visión profunda y detallada de los elementos clave para su éxito. A continuación, presento un resumen enriquecido de los conceptos más destacados que se discutieron durante la sesión.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Aspectos Cruciales en el Proceso de Ventas
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Para garantizar una propuesta de proyecto exitosa, se enfatizó en tres ámbitos fundamentales:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Ámbito Técnico:</strong> Se resaltó la importancia de presentar una descripción técnica del proyecto que sea al mismo tiempo legible y atractiva. La claridad en la exposición de los detalles técnicos, sin caer en excesos, es crucial para captar el interés del cliente.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Lobby y Relación con los Clientes:</strong> Se subrayó la relevancia de establecer relaciones sólidas con los clientes, a través de actividades como comidas y obsequios. Este enfoque, aunque demanda tiempo, es esencial para generar confianza y suele tener un impacto positivo en la fortaleza de los otros dos ámbitos.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Aspecto Económico:</strong> Se destacó la necesidad de prestar atención al presupuesto. En un mercado competitivo, es vital ofrecer precios que sean atractivos sin descuidar la calidad y rentabilidad del proyecto.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Fases del Proceso de Venta
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enumeraron las etapas críticas en el ciclo de ventas de un proyecto:
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Detección de Necesidades:</strong> Identificar las necesidades del cliente es el primer paso esencial.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Persuasión:</strong> Convencer al cliente de la idoneidad de nuestros servicios para satisfacer sus necesidades.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Negociación:</strong> Discutir los términos y condiciones del proyecto.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Acuerdo:</strong> Alcanzar un consenso y formalizar el contrato.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Ejecución:</strong> Implementar el proyecto conforme a lo acordado.
                    </Typography>
                </li>
            </ol>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Además, se mencionó que, en ocasiones, el ciclo comercial puede iniciar con la identificación de oportunidades en lugar de la detección de necesidades. Esto requiere un monitoreo constante de los clientes actuales y potenciales, siendo crucial la proactividad para anticiparse y no verse en la necesidad de reducir precios de manera significativa (pudiendo acarrear pérdidas sustanciales).
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Pilares para la Aceptación del Proyecto por el Cliente
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se expusieron cuatro pilares fundamentales para lograr que el cliente acepte el proyecto:
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Preparación:</strong> Llegar a las reuniones con información relevante y específica sobre el cliente.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Contacto con el cliente:</strong> Priorizar la escucha activa y generar una impresión positiva desde el primer encuentro.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Detectar necesidad:</strong> Entender y motivar al cliente, abordando las preocupaciones de los distintos tipos de compradores.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        <strong>Resolver objeciones:</strong> Manejar adecuadamente aspectos como el precio y las concesiones, evitando malentendidos y asegurando que se documenten correctamente.
                    </Typography>
                </li>
            </ol>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enfatizó la importancia de gestionar adecuadamente el forecast de oportunidades y de manejar los proyectos de manera que no afecten negativamente a la cartera de proyectos en curso, prestando atención especial a aspectos como el solapamiento de equipos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Enfoque en Licitaciones y Mantenimiento de Sistemas
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Finalmente, se destacó la importancia de elaborar un resumen ejecutivo atractivo y completo para las licitaciones, siendo este un factor determinante para superar las primeras etapas de selección. También se reflexionó sobre cómo el mantenimiento de sistemas previamente desarrollados por la empresa representa una oportunidad significativa para fortalecer la relación con el cliente.
            </Typography>
        </>
    )
}

function getConcepts8() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Esta charla ha sido esencial para mi comprensión de cómo nace un proyecto, un aspecto que no había considerado profundamente hasta ahora. Aprender sobre el proceso de ventas ha sido particularmente enriquecedor, ofreciéndome una nueva perspectiva sobre la dirección de proyectos. Este entendimiento de las etapas preliminares de un proyecto es fundamental para comprender el panorama completo de la gestión de proyectos.
        </Typography>
    )
}

function getQuestions8() {
    return(
        <>
            <Typography variant='body1' style={{marginBottom: '20px'}}>
                Durante el desarrollo de la sesión, le formulé dos preguntas al ponente. En primer lugar le pregunté: "¿Durante el primer contacto con el cliente que ocurre si empiezas con mal pie ya sea por mala preparación o por mala suerte?"
            </Typography>
            <Typography variant='body1' style={{marginBottom: '20px'}}>
                El ponente respondió: "No hay que ser ni agresivos ni aplicar técnicas que separen emocionalmente al cliente de nosotros. Mientras no se rompa la confianza no pasa nada, aunque puede dificultar la contratación por parte del cliente".
            </Typography>
            <Typography variant='body1' style={{marginBottom: '20px'}}>
                También le pregunté: "¿Cómo se hace en el sector privado para acertar con el presupuesto a solicitar?"
            </Typography>
            <Typography variant='body1' style={{marginBottom: '30px'}}>
                Respecto a esta pregunta, su respuesta fue: "No se conoce la forma de calcular el presupuesto óptimo, pero suele ser un proceso iterativo en el que se va dando la oportunidad de ajustar el precio. En el público se detalla en el pliego de cláusulas administrativas como se calcula el presupuesto (márgenes de beneficio, matriz de valoración…) mientras que en el privado la empresa que se presenta para intentar ganar el contrato tiene que justificar su presupuesto y explicar de dónde sale".
            </Typography>
        </>
    )
}

function getReflection8() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La charla proporcionó una visión muy valiosa sobre una etapa de los proyectos que generalmente no se aborda en asignaturas convencionales. La dinámica interactiva de la sesión permitió una inmersión más profunda en estos aspectos menos conocidos, lo que fue especialmente beneficioso. Considero que esta información es crucial para aquellos interesados en emprender o avanzar en su carrera, ya que ofrece una perspectiva única y práctica que raramente se obtiene de antemano.
        </Typography>
    )
}

function getHelp8() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Aunque los contenidos de esta charla no estuvieron directamente relacionados con el caso práctico abordado, el conocimiento adquirido sobre la fase inicial de un proyecto y la gestión de relaciones con clientes es invaluable y puede ser aplicado en una variedad de contextos profesionales. Esta comprensión de las etapas tempranas del desarrollo de proyectos es una herramienta importante para cualquier profesional en el campo de la gestión de proyectos.
        </Typography>
    )
}

function getContext7() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                En el marco de un desafiante proyecto internacional, se presentó una iniciativa compleja en la que intervino el ponente, en calidad de responsable de proyecto. La empresa Ayesa fue contratada por Enel, compañía que a su vez tenía como cliente al reconocido banco Scotiabank. La estimación inicial del proyecto (implantar un nuevo liquidador), de gran envergadura, preveía una duración de 8 meses. Sin embargo, la complejidad y magnitud de los desafíos enfrentados extendieron este periodo a 2 años y 10 meses.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El proyecto, que formaba parte de una iniciativa más amplia de convergencia, tenía como objetivo central la unificación de todos los sistemas de Enel en Colombia. Esta estrategia exigía que todos los proyectos subyacentes se implementaran simultáneamente, lo que añadió una capa adicional de complejidad.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Durante su exposición, el ponente destacó que se llevaron a cabo hasta 5 pruebas de migración de datos, y aunque se mejoró progresivamente, ninguno de los intentos culminó en un resultado perfecto. Este hecho resalta la intrincada naturaleza del proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El equipo que trabajó en este proyecto era tanto distribuido como internacional, compuesto por 11 personas en Colombia y 18 en España. Después del primer ciclo de pruebas, que abarcó un total de 350, se evidenciaron numerosas fallas en la aplicación, lo que derivó en una crisis de confianza por parte del cliente hacia el proyecto. En este punto crítico, el ponente asumió la dirección del proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se explicó que las directrices del proyecto provenían de Italia, mientras que en España se centralizaban las tareas de dirección, arquitectura, mejoras y rendimiento, y en Colombia se enfocaban en la parte funcional y de desarrollo.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                El ponente identificó y abordó varios problemas claves, junto con sus correspondientes soluciones:
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Inestabilidad de la aplicación debido a una arquitectura deficiente. Se optó por un equipo de arquitectos en lugar de un único profesional para el diseño del software.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Abundancia de errores en las pruebas. Se incentivó la responsabilidad del equipo en la solución de estos fallos.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Dificultades en la comunicación debido a la dispersión geográfica del equipo. Se recomendó limitar las reuniones a un máximo de 8 o 10 personas para mejorar la eficacia comunicativa.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Diferencias culturales en el equipo, que en el caso de Colombia, se traducían en una tendencia a evitar conflictos y una sobreestimación de capacidades. Se implementó un registro de riesgos exhaustivo y una cultura de comunicación directa y honesta.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Plazos ajustados y alta presión del cliente en un contexto de convergencia. El ponente subrayó la importancia de fomentar el compromiso y la implicación del equipo, así como la relevancia de proporcionar información útil y veraz al cliente.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Bajo rendimiento de la aplicación. Se realizaron pruebas exhaustivas y se integró un equipo especializado para abordar este problema.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" style={{marginBottom: '20px'}}>
                        Control de calidad deficiente. Se mantuvieron y reforzaron los procedimientos y herramientas para gestionar y asegurar la calidad del proyecto.
                    </Typography>
                </li>
            </ol>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                El resultado final fue exitoso y satisfactorio tanto para el cliente como para el equipo. La aplicación resultó ser robusta, fiable y de alto rendimiento, sin pérdidas significativas de datos durante la migración. Las incidencias registradas fueron mínimas y aisladas. Este éxito llevó a que el cliente confiara en la empresa para continuar con el soporte de operaciones y usuarios. Además, el proyecto se ha convertido en un referente de calidad para la empresa en el ámbito del software.
            </Typography>
        </>
    )
}

function getConcepts7() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La charla fue esencial para entender cómo una gestión efectiva puede ser clave en la recuperación de proyectos que han desviado de su curso. La presentación de problemas específicos y sus soluciones no solo me ha enseñado cómo actuar frente a estas situaciones, sino también cómo prevenir estos desafíos desde el comienzo. Esta comprensión mejora mi habilidad para anticipar y mitigar problemas en la gestión de proyectos.
        </Typography>
    )
}

function getQuestions7() {
    return(
        <>
            <Typography variant='body1' style={{marginBottom: '20px'}}>
                Al ponente le formulé la siguiente pregunta: "¿Dirías que el error más importante que ha sucedido en el proyecto ha sido el de una mala dirección del proyecto?"
            </Typography>  
            <Typography variant='body1' style={{marginBottom: '30px'}}>
                Su respuesta fue la siguiente: "Más bien fue una mala toma de decisiones. Desde el principio se infravaloró el proyecto y no se incluyeron a personas de España para realizar una correcta supervisión del proyecto hasta que saltaron los problemas. Además, había muy pocas pruebas realizadas y demasiada libertad para el equipo".
            </Typography>
        </>
    )
}

function getReflection7() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La charla me proporcionó una perspectiva única, al ser la primera en la que se enfocó en la experiencia práctica en lugar del conocimiento teórico. Aprender de la experiencia del ponente en la gestión de situaciones complejas y problemáticas fue una lección valiosa. Me quedo con la enseñanza de que incluso en proyectos llenos de conflictos y aparentemente destinados al fracaso, una buena gestión puede transformar la situación y resultar en un producto exitoso que refuerce la confianza del cliente.
        </Typography>
    )
}

function getHelp7() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Esta charla resultó muy útil para abordar el caso práctico, especialmente debido a las similitudes entre los planteamientos iniciales de ambos proyectos y los errores comunes presentados en la charla. La similitud entre la intervención del ponente en el proyecto y nuestra llegada al caso práctico fue particularmente relevante, ya que ambos aspectos implicaron unirse a proyectos ya avanzados y plagados de desafíos de gestión. Esta experiencia y conocimiento adquiridos fueron fundamentales para abordar eficazmente los problemas y contribuir al éxito del caso práctico.
        </Typography>
    )
}

function getContext6() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                En la sesión se abordaron los aspectos esenciales del aseguramiento y control de la calidad en la gestión de proyectos TI. El contenido presentado fue el siguiente:
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Conceptos Básicos sobre la Calidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La sesión inició con las definiciones fundamentales de calidad y calidad del software, enfocándose en la funcionalidad, ejecución, confiabilidad, disponibilidad, y costos. Se introdujeron varias teorías de calidad y se explicó el funcionamiento de la certificación en los estándares de calidad centrándose en las ventajas y desventajas que tienen.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Metodologías de Aseguramiento de la Calidad
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se analizó el aseguramiento de la calidad en MÉTRICA V3 (mediante una interfaz) y CMMI V2.0 (mediante un área de práctica), describiendo cómo estos marcos proporcionan un referente para la definición y ejecución de planes de calidad en proyectos específicos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Gestión de la Calidad en PMBOK
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                En esta sección se profundizó en los procesos de gestión de calidad en el contexto de PMBOK, incluyendo la planificación, gestión y control de la calidad, junto con un análisis costo-beneficio.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Gestión de la Calidad en una OTC
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se detallaron los objetivos, fases, y requisitos para la gestión de la calidad en una Oficina Técnica de Calidad (OTC), incluyendo perfiles de responsabilidad y habilidades necesarias para el personal involucrado.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Conclusiones
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Se resumieron los aspectos clave de la gestión de calidad desde la perspectiva de las principales metodologías y estándares, destacando principios como la orientación al cliente, liderazgo, participación del personal y mejora continua.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En la sesión también se desarrolló un ejercicio práctico para poner en común los conocimientos recién adquiridos de gestión de la calidad.
            </Typography>
        </>
    )
}

function getConcepts6() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Esta sesión ha sido una oportunidad excelente para refrescar y ampliar mis conocimientos en el campo del aseguramiento de la calidad. Mientras que reforzaba mis entendimientos previos, pude integrar nuevos aprendizajes, especialmente aquellos relacionados con la función y misión de una Oficina Técnica de Calidad. Esta combinación de conocimientos antiguos y nuevos ha proporcionado una comprensión más completa y matizada de cómo se puede mejorar y mantener la calidad en los proyectos de tecnologías de la información (TI).
        </Typography>
    )
}

function getReflection6() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Desde mi perspectiva, el aseguramiento de la calidad es a menudo un aspecto subestimado y descuidado en la gestión de proyectos de TI. Comúnmente, se convierte en una de las primeras áreas afectadas cuando surgen problemas con los plazos de entrega. Veo una analogía entre el aseguramiento de la calidad en TI y la visualización en la ingeniería de datos: ambos son importantes pero frecuentemente pasados por alto. Esta sesión ha enfatizado teórica y prácticamente la importancia de enmarcar la gestión de proyectos de TI dentro de un marco de calidad sólido, resaltando la necesidad de mantener siempre la calidad como un pilar central en cualquier proyecto de TI.
        </Typography>
    )
}

function getHelp6() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            El caso de estudio presentado ilustra un proyecto en el que la gestión de calidad no ha sido adecuadamente atendida. La relevancia de esta sesión es directamente aplicable a la sexta pregunta del caso, donde se requería identificar aspectos del proyecto para mejorar su calidad. En nuestra propuesta, además de sugerir mejoras intuitivas, incluimos varias medidas a nivel organizacional que la empresa podría implementar para mejorar la gestión de la calidad en todos sus proyectos. Este enfoque integral, destacado en la sesión, fue fundamental para desarrollar una respuesta completa y efectiva que abordara los problemas de calidad del proyecto desde una perspectiva organizacional amplia.
        </Typography>
    )
}

function getContext5() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                En la sesión se detallaron múltiples aspectos relacionados a la implantación y el mantenimiento de sistemas de información. A continuación, se proporciona un resumen de dicho contenido:
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Introducción
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se discutió el ciclo de vida de los sistemas de información, desde la toma de requisitos hasta la implantación y mantenimiento, asegurando la calidad en cada etapa y enmarcando dichas etapas en un contexto metodológico.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Establecimiento del Plan de Implantación
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se destaca la importancia de la planificación para asegurar compromisos, optimizar recursos, identificar necesidades y riesgos, y mejorar la distribución de tareas. Se desglosaron los siguientes planes, incluyendo:
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Estrategia de implantación:</strong> Se mencionaron los tipo existentes: completa y sincronizada, fragmentada (fases temporales o espaciales) o en paralelo.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Plan de Transición:</strong> Se explicó que su propósito era garantizar una transición suave afectando lo mínimo a la operativa del cliente. Se explicaron los procesos asociados a este plan.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Plan de Contingencia:</strong> Se detallaron las características de los riesgos y se recalcó la importancia de un buen análisis.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Plan de Comunicación:</strong> Se resaltó la importancia de una buena clasificación de los stakeholders y se definieron dos planes para realizar la comunicación: Plan de Comunicación Interno (orientado a los empleados) y Plan de comunicación Externo (orientado hacia el público de interés).
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Plan de Capacitación:</strong> La adecuación de los perfiles profesionales de los trabajadores junto a una debida motivación es el objetivo del plan. Gracias a este plan es posible que los trabajadores se comprometan a integrar el nuevo sistema y el rechazo sea mínimo.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                <strong>Plan de Despliegue de Infraestructuras:</strong> Se dirige a la identificación de las necesidades de la infraestructura para realizar la instalación.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>Plan de Soporte Post-Implantación / Seguimiento:</strong> Imprescindible para poder realizar un seguimiento tras la implantación y resolver cualquier incidencia que surja.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Mantenimiento
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se introdujo el concepto de mantenimiento de los SI, abarcando las dificultades y tipologías (según ITIL), así como la puesta en marcha de servicios de mantenimiento. Se discuten procedimientos para la gestión de incidencias, herramientas de gestión, gestión de la demanda y costes asociados al mantenimiento, y la definición y particularidades de los Acuerdos de Nivel de Servicio (ANS).
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Durante la sesión se proporcionó una visión holística y práctica de la gestión de proyectos de TI, enfocándose en la implantación y mantenimiento eficiente de sistemas de información.
            </Typography>
        </>
    )
}

function getConcepts5() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La sesión ha sido excepcionalmente valiosa, proporcionándome un conocimiento profundo sobre cómo realizar un plan de implantación efectivo y un servicio de mantenimiento eficiente para sistemas de información (SI). Lo más destacable ha sido la manera en que el ponente ha integrado su experiencia práctica, utilizando ejemplos concretos que han dado vida a los conceptos teóricos. Esta aproximación no solo ha facilitado la comprensión de los temas, sino que también ha subrayado su importancia y aplicabilidad en situaciones reales.
        </Typography>
    )
}

function getReflection5() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            A lo largo de mi carrera académica, he participado en numerosos proyectos enfocados en la gestión y construcción de SI, pero a menudo estos proyectos han descuidado aspectos cruciales como la implantación y el mantenimiento. Esta sesión ha sido esclarecedora, destacando la importancia de estas fases a menudo subestimadas en el proyecto. Personalmente, considero que este enfoque integral es fundamental para la gestión exitosa de cualquier SI.
        </Typography>
    )
}

function getHelp5() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            En relación con el caso de estudio, la relevancia de esta sesión ha sido crucial, especialmente en lo que respecta a la cuarta pregunta, centrada en la implantación de un nuevo SI. Mi involucramiento directo en la respuesta a esta pregunta en el caso práctico de mi grupo ha hecho que los conocimientos adquiridos en esta sesión sean particularmente pertinentes y aplicables. Además, la quinta pregunta, que requería la redacción de un ANS, se ha beneficiado enormemente de la comprensión detallada del mantenimiento obtenida en esta sesión. Estos aprendizajes han sido fundamentales para abordar estas preguntas de manera efectiva y para contribuir significativamente al éxito del caso práctico.
        </Typography>
    )
}

function getContext4() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La sesión fue enfocada en la construcción de sistemas de información (SI) utilizando diferentes metodologías y arquitecturas. A continuación, se proporciona un breve resumen del contenido de la misma:
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Introducción
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se abordó el ciclo de vida del software y los modelos de ciclo de vida, sentando las bases para discutir las metodologías de gestión en la construcción de sistemas de información.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Metodologías de Gestión en la Construcción de un SI
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se comparan los dos enfoques principales: Waterfall y Agile.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>Waterfall:</strong> Se presentó como un método secuencial y lineal, enfocado en completar cada fase del ciclo de vida del software antes de avanzar. Se destacó su adecuación en proyectos con requisitos estables y bien definidos.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>Agile:</strong> Se describió como un enfoque iterativo e incremental, enfocado en la colaboración y adaptación continua. Se subrayó su flexibilidad y capacidad de entregar software funcional rápidamente.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Metodologías Ágiles Detalladas
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>Scrum:</strong> Se vuelven a resaltar sus ventajas como la gran eficiencia y flexibilidad, y también sus inconvenientes, como la dificultad de implementación y la necesidad de mayor colaboración.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>Kanban:</strong> Se discuten sus beneficios, como la mayor visibilidad y control del proceso de trabajo, y sus desafíos, como la dificultad de implementarlo en organizaciones con procesos muy estructurados.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                <strong>DevOps:</strong> Se presentan tanto los puntos fuertes que posee, como es la mejora de la comunicación y colaboración entre los equipos, junto a los puntos débiles, como es el cambio cultural necesario en la organización para una correcta implantación de la metodología.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Arquitectura de un SI
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se exploraron diferentes paradigmas arquitectónicos en el desarrollo de software, incluyendo arquitecturas monolíticas (el pasado), microservicios+cloud y serverless (el presente). Cada uno con sus pros y contras, como la simpleza aunque con más limiada de las arquitecturas monolíticas frente a la facilidad en la escalabilidad pero con una complejidad más alta de las arquitecturas de microservicios o la ventaja de abstracción del hardware pero requiriendo una monitorización más compleja de las arquitecturas serverless.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Otras Alternativas en Arquitecturas
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se mencionaronn alternativas como monolitos modulares, servicios de dominio y nanoservicios, discutiendo brevemente sus características y aplicaciones.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Especialización en el Desarrollo
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se abordó la diferenciación entre frontend y backend, sugiriendo una posible especialización en estas áreas.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Programa Talento
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Se describió el programa, presente en Ayesa, destinado a profesionales en tecnologías de la información, destacando las oportunidades de crecimiento y formación profesional que incluía.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Esta sesión proporcionó una visión general muy provechosa sobre las metodologías y arquitecturas clave en la construcción de los sistemas de información.
            </Typography>
        </>
    )
}

function getConcepts4() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            En esta sesión, lo más destacado ha sido la comprensiva explicación sobre la evolución histórica de la arquitectura de sistemas en el desarrollo de software. He encontrado particularmente fascinante la parte dedicada a las arquitecturas serverless, un área en la que tenía menos conocimientos previos. Además, ha sido enriquecedor descubrir las diversas alternativas existentes a las arquitecturas convencionales como las monolíticas, los microservicios y las serverless. Esta ampliación de mi conocimiento sobre las distintas opciones disponibles en arquitecturas de sistemas ha sido una valiosa adición a mi educación en este campo.
        </Typography>
    )
}

function getReflection4() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Reitero mi interés particular en la sección que aborda la evolución de las arquitecturas de sistemas. La presentación de las arquitecturas serverless como una realidad actual y emergente me parece completamente acertada. Aunque hoy en día los microservicios sean comúnmente la opción preferida en la construcción de sistemas de información (SI), es evidente que el uso de arquitecturas y funciones serverless está ganando terreno rápidamente. Esta perspectiva me ha permitido entender mejor el panorama actual del desarrollo de software y sus tendencias futuras.
        </Typography>
    )
}

function getHelp4() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            En lo que respecta al caso de estudio, que trata de un sistema en desarrollo, esta sesión ha sido crucial para entender los aspectos necesarios para gestionar adecuadamente la construcción de un SI. La comprensión detallada de las distintas arquitecturas y su evolución me proporciona un marco teórico para contextualizar y abordar eficientemente los desafíos presentes en el caso práctico. Esta información es fundamental para tomar decisiones informadas y estratégicas en la planificación y desarrollo de sistemas complejos.
        </Typography>
    )
}

function getContext3() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La sesión presentó una visión exhaustiva sobre la gestión de proyectos tecnológicos, enfocándose en aspectos clave como la toma de requisitos, análisis y diseño de sistemas de información. El contenido de la sesión se distribuyó de la siguiente forma:
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Áreas de Conocimiento en Proyectos de TI
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se abordaron las áreas Negocio del cliente, Tecnología, y la gestión del Proyecto TIC, enfocándose en aspectos como la toma de requisitos dependiendo de la fase en la que se encuentre el proyecto a gestionar.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Análisis y Diseño de Sistemas de Información
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enfatizó la importancia del análisis y diseño en proyectos de TI. Para ello, se describierón sus objetivos, características para realizar un buen análisis y un buen diseño, problemas habituales encontrados y los actores involucrados.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Metodologías de Desarrollo
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se discutió el uso de metodologías como Waterfall y Agile, detallando sus ventajas, desventajas y aplicaciones específicas en el contexto de la gestión de proyectos TI. También se mencionaron las características de las metodologías Devops y las orientadas a la automatización de tests (Test Driven Development y Behaviour Driven Development).
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Aspectos Técnicos en Análisis y Diseño
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se explorarón los elementos técnicos del diseño de sistemas de información, incluyendo arquitecturas, principios de diseño, patrones arquitectónicos y de diseño y paradigmas de programación. Se presentaron herramientas colaborativas específicas tanto para realizar el análisis como el diseño de un proyecto TIC.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Casos Prácticos y Experiencias
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                El ponente concluyó la sesión utilizando ejemplos prácticos y experiencias propias en la gestión de proyectos de TI, mostrando ejemplos de aplicaciones reales relevantes para los conceptos discutidos en las que había trabajado.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                En resumen, la sesión fue guía completa para los interesados en la gestión y dirección de proyectos de TI, abarcando desde los fundamentos metodológicos hasta aspectos técnicos avanzados y estudios de caso.
            </Typography>
        </>
    )
}

function getConcepts3() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Esta sesión ha sido fundamental para aprender y consolidar una serie de buenas prácticas esenciales en el diseño y análisis de proyectos. A través de ella, he podido no solo reforzar, sino también expandir los conocimientos adquiridos en otras asignaturas del grado, especialmente en lo que respecta a la toma de requisitos y diseño de sistemas de información (SI). Además, los conceptos relacionados con la metodología han jugado un papel clave en refrescar y enriquecer mi comprensión de los contenidos de sesiones anteriores, permitiéndome abordar la gestión y dirección de proyectos desde una perspectiva más amplia y matizada.
        </Typography>
    )
}

function getReflection3() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La última sección de la presentación ha sido particularmente impactante para mí. La incorporación de experiencias reales ha añadido un valor inmenso al contenido teórico, ofreciendo una visión práctica y tangible de los conceptos aprendidos. Esta aproximación basada en la experiencia real marca una diferencia significativa con respecto a otras asignaturas del máster o del grado, brindando un contexto más profundo y aplicado que enriquece enormemente el proceso de aprendizaje.
        </Typography>
    )
}

function getHelp3() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            En lo que respecta al caso de estudio, he encontrado extremadamente relevante la sección dedicada a los problemas habituales en el análisis. Las similitudes entre estos problemas comunes y los desafíos presentados en el caso práctico son notables. Esta correlación ha proporcionado una perspectiva práctica valiosa, permitiéndome visualizar cómo los conceptos y técnicas aprendidos pueden ser aplicados directamente para abordar y resolver problemas reales dentro del contexto del caso de estudio.
        </Typography>
    )
}

function getContext2() {
    return(
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La sesión versó sobre la necesidad de adoptar una metodología para los proyectos y se presentaron los principales enfoques actuales: ITIL 4, Lean, metodologías ágiles, y DevOps.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Introducción y Conceptos Básicos
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enfatizó la importancia de adoptar metodologías en el desarrollo de aplicaciones de software para satisfacer las necesidades de las partes interesadas, destacando aspectos como funcionalidad, confiabilidad, mantenimiento, conformidad y auditabilidad.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                También se presentaron las ventajas de utilizar una metodología en la gestión, para el cliente y en el producto final, como facilitar la planificación, control, y seguimiento, y optimizar el uso de recursos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                ITIL 4
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                ITIL 4 se definió como un <strong>marco de trabajo</strong> para la gestión del servicio, priorizando estrategias y soluciones, y asegurando procesos y participación adecuados para alinear <strong>resultados</strong> con la <strong>estrategia y valores</strong> de la organización.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Lean
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se explicó el enfoque Lean en la <strong>creación de valor</strong> para el cliente, enfocándose en la <strong>eliminación de desperdicios</strong> y sobrecarga, y en <strong>establecer flujos continuos</strong> y sincronizados entre procesos.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Metodologías Ágiles y Marco SCRUM
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se abordarón las prácticas ágiles, destacando su <strong>enfoque iterativo</strong> y la <strong>importancia de la retroalimentación</strong> en el desarrollo de productos. Se detalla el marco de Scrum, un conjunto de reglas y prácticas que promueven la gestión evolutiva y la calidad basada en el conocimiento.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                DevOps
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                DevOps se describió como un <strong>modelo cultural y operativo</strong> que fomenta la <strong>colaboración</strong> y habilita a TI de alto rendimiento para lograr objetivos comerciales. Se destacó su importancia en la cultura de aprendizaje y experimentación continua.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Conclusiones
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Se concluyó que las metodologías presentadas son fundamentales para aplicar una estrategia de TI adecuada, destacando la importancia de comprender los servicios y la gestión de los mismos, así como la <strong>mejora continua</strong>.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                Esta sesión, por tanto, proporcionó una visión comprensiva de las metodologías actuales en la gestión de TI, resaltando la importancia de adaptarse a los cambios tecnológicos y a las necesidades del cliente para el desarrollo eficiente y efectivo de servicios y productos de TI.
            </Typography>
        </>
    )
}

function getConcepts2() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            A lo largo de mi carrera académica, he tenido la oportunidad de emplear metodologías ágiles en la gestión de varios proyectos, lo cual me ha proporcionado una base sólida en este enfoque. Sin embargo, esta sesión ha sido particularmente iluminadora al introducirme a conceptos nuevos y fundamentales fuera del ámbito de las metodologías ágiles, especialmente en campos como la innovación. Esta expansión de conocimientos me ha permitido apreciar la importancia y versatilidad de estos enfoques en diversas áreas de gestión, enriqueciendo mi perspectiva y habilidades profesionales.
        </Typography>
    )
}

function getReflection2() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Los enfoques presentados en esta sesión, en especial el método Lean, me han parecido sumamente interesantes y prometedores. Estoy acostumbrado a trabajar con metodologías ágiles, y descubrir cómo el Lean puede integrarse y potenciar estos métodos ha sido una revelación. Veo en Lean un complemento valioso que puede enriquecer y maximizar los beneficios de las metodologías ágiles, abriendo nuevas posibilidades para la gestión eficiente de proyectos.
        </Typography>
    )
}

function getHelp2() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            El caso de estudio que hemos analizado ilustra perfectamente la importancia de una metodología adecuada para la buena gestión y el éxito de un proyecto. Me ha hecho reflexionar sobre cómo algunos de los problemas identificados en el proyecto podrían abordarse eficazmente mediante la aplicación de una metodología ágil. Esta comprensión me ha proporcionado una valiosa perspectiva sobre cómo integrar y aplicar estos métodos en situaciones reales, demostrando su relevancia y eficacia en la resolución de problemas prácticos.
        </Typography>
    )
}

function getContext1() {
    return (
        <>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                La sesión comenzó con una visión general de la asignatura. Durante la presentación se abordaron los siguientes apartados:
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Definición de Proyecto
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se discutió la definición de proyecto, programa y portfolio, destacando (de los proyectos) su naturaleza temporal y su objetivo de crear productos, servicios o resultados únicos. También se mencionaron las características de los proyectos, como su unicidad, temporalidad y progresividad.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Rol del Project Manager
            </Typography>
            <Typography variant="body1" style={{marginBottom: '20px'}}>
                Se enfocó en las funciones generales, habilidades y responsabilidades de un gestor de proyectos, así como en los desafíos típicos a los que se enfrenta.
            </Typography>
            <Typography variant="h6" style={{marginBottom: '20px', textDecoration: 'underline'}}>
                Fase de Inicio del Proyecto
            </Typography>
            <Typography variant="body1" style={{marginBottom: '10px'}}>
                Esta sección fue la más extensa y abordó diversos aspectos de la fase de inicio de un proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Alcance:</strong> Se definió el concepto de alcance y se enfatizó su importancia para el proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Entorno:</strong> Se profundizó en la influencia del entorno en el proyecto y se presentaron herramientas para su gestión.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Recursos Humanos:</strong> Se tratan temas como la importancia de un correcto manejo de los recursos humanos y se ofrecieron soluciones a problemas frecuentes en este área.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Proveedores:</strong> Se dieron consejos sobre cómo tomar decisiones acerca de los proveedores.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Recursos Materiales:</strong> Al igual que con los recursos humanos, se resaltó la importancia de tener bien considerados los recursos materiales que van a utilizarse en el proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Metodologías:</strong> En este apartado se explicó las razones para el uso de las metodologías en los proyectos y se destacó la relevancia de elegir la adecuada en función de las características del proyecto y no por otras razones (la metodología es un medio, no el fin).
            </Typography>
            <Typography variant="body1" style={{marginBottom: '5px'}}>
                <strong>Otros Aspectos:</strong> Se abordaron otros aspectos como los adminitrativos y financieros, las comunicaciones y la calidad. De todos estos aspectos se destacaron las claves a considerar durante el inicio del proyecto.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '30px'}}>
                <strong>Kick-off Meeting:</strong> La presentación concluyó con una mención a la reunión de inicio del proyecto, resaltando su importancia para establecer los objetivos, conocer a los stakeholders principales y gestionar sus expectativas desde el principio del proyecto.
            </Typography>
        </>
    )
}

function getConcepts1() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            En esta sesión, a pesar de mi familiaridad previa con los temas abordados, proveniente de mis estudios de máster y grado, he encontrado una oportunidad única para profundizar mi comprensión sobre esta fase crucial de los proyectos. Ha sido especialmente revelador entender el significado y la trascendencia de los aspectos discutidos, permitiéndome apreciar en mayor medida su relevancia e impacto en el éxito de cualquier proyecto.
        </Typography>
    )
}

function getReflection1() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            La sesión me ha resultado sumamente enriquecedora. Aunque muchos de los conceptos no eran nuevos para mí, la forma en que se han presentado y debatido ha aportado una perspectiva fresca y profundamente esclarecedora. Estoy convencido de que los conocimientos adquiridos serán de gran utilidad práctica, especialmente al afrontar los retos inherentes a la fase inicial de un proyecto. Esta experiencia ha reforzado mi comprensión y ha contribuido significativamente a mi desarrollo profesional.
        </Typography>
    )
}

function getHelp1() {
    return(
        <Typography variant='body1' style={{marginBottom: '30px'}}>
            Si bien esta sesión ha tenido un carácter más introductorio, considero que los conocimientos adquiridos son de gran valor. Aunque en esta ocasión no han sido directamente aplicables al caso de estudio específico, proporcionan una base teórica sólida y un marco de referencia esencial para futuras aplicaciones prácticas. Esta comprensión teórica es fundamental para abordar con éxito los desafíos prácticos que se presenten en etapas posteriores del proyecto.
        </Typography>
    )
}

export default blogs;