export const contactsData = {
    email: 'me@javiercavlop.com',
    //phone: '+8801608797655',
    address: 'Sevilla, España',
    github: 'https://github.com/javiercavlop',
    //facebook: 'https://www.facebook.com/abusaid.riyaz/',
    linkedIn: 'https://www.linkedin.com/in/javiercavlop/',
    // twitter: 'https://twitter.com/said7388',
    // medium: 'https://medium.com/@abusaid-bd',
    // stackOverflow: 'https://stackoverflow.com/users/16840768/abu-said',
    devUsername: "javiercavlop"
}