import img from '../assets/jpg/me.jpg';
import imgbw from '../assets/jpg/mebw.jpg';

export const headerData = {
    name: 'Francisco Javier Cavero López',
    title: "¡Bienvenidos a mi portfolio!",
    desciption: "¡Bienvenidos a mi portfolio! Mi nombre es Francisco Javier Cavero López, y este documento es un reflejo de mi trayectoria académica en la asignatura Dirección de Proyectos del Máster en Ingeniería del Software: Cloud, Datos y Gestión de las Tecnologías de la Información.",
    image: img,
    imagebw: imgbw,
    resumePdf: './downloads/Porfolio_DP.pdf',
}
