import eight from '../assets/png/deloitte.png'
import seven from '../assets/png/iaas.png'
import six from '../assets/png/ayesa.png'
import nine from '../assets/png/solutia.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'Ayesa',
        projectDesc: 'Con 57 años de experiencia, esta empresa crea las soluciones necesarias para impulsar una nueva realidad mejor y más digital."',
        tags: ['6 sesiones', '1 conferencia'],
        code: 'https://www.ayesa.com',
        demo: '/blog?enterprise=Ayesa',
        image: six
    },
    {
        id: 2,
        projectName: 'IaaS365',
        projectDesc: 'Proveedora de soluciones y servicios tecnológicos globales, estratégicos e innovadores, su equipo cuenta con más de 15 años de experiencia y reconocimiento en el mundo de los sistemas TI y de los entornos críticos y dispone de las máximas certificaciones del sector.',
        tags: ['1 conferencia'],
        code: 'https://www.iaas365.com',
        demo: '/blog?enterprise=IaaS365',
        image: seven
    },
    {
        id: 3,
        projectName: 'Deloitte',
        projectDesc: 'Firma de servicios profesionales orientada a la prestación de servicios de auditoría, consultoría, riesgos, asesoramiento fiscal y legal y asesoramiento financiero.',
        tags: ['1 conferencia'],
        code: 'https://www2.deloitte.com/es/es.html',
        demo: '/blog?enterprise=Deloitte',
        image: eight
    },
    {
        id: 4,
        projectName: 'Grupo Solutia',
        projectDesc: 'Esta empresa ofrece servicios a medida, con una atención personal, flexible y especializada, aplicando tecnologías de última generación.',
        tags: ['1 conferencia'],
        code: 'https://gruposolutia.com',
        demo: '/blog?enterprise=Grupo%20Solutia',
        image: nine
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/