import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import BlogPageUI from '../../components/core-ui/blog-page/blog-page-ui';
import { ThemeContext } from '../../contexts/theme-context';
import blogs from '../../data/blogData';
import { useSearchParams } from 'react-router-dom';
import { parseDate } from '../../utils/timeConverter';
import { skillsData } from '../../data/skillsData';
import { headerData } from '../../data/headerData';

function BlogPage() {
    const [search, setSearch] = useState('')
    const { theme } = useContext(ThemeContext);
    const [filteredArticles, setFilteredArticles] = useState(blogs)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        let enterprise = searchParams.get('enterprise')
        if (enterprise) {
            setFilteredArticles(search?blogs?.filter((blog) => {
                if (blog?.content) {
                    return false
                } else {
                    if (blog.enterprise.toLowerCase() !== enterprise.toLowerCase()){
                        return false
                    }
                    let content = blog.title + blog.description + parseDate(blog.published_at) 
                    
                    if (blog?.enterprise) content += blog.enterprise + skillsData[blog.id - 1].speaker
                    if (blog?.content) content += headerData.name

                    return  content.toLowerCase().includes(search.toLowerCase()) }
                }):blogs.filter((blog) => {
                    if (blog?.content) {
                        return false
                    }
                    return blog.enterprise.toLowerCase() === enterprise.toLowerCase()}))
        } else{
            setFilteredArticles(search?blogs?.filter((blog) => {
                let content = blog.title + blog.description + parseDate(blog.published_at) 
                
                if (blog?.enterprise) content += blog.enterprise + skillsData[blog.id - 1].speaker
                if (blog?.content) content += headerData.name

                return content.toLowerCase().includes(search.toLowerCase())
            }):blogs)
        }
    }, [searchParams, search])

    const useStyles = makeStyles((t) => ({
        search: {
            color: theme.tertiary,
            width: '40%',
            height: '2.75rem',
            outline: 'none',
            border: 'none',
            borderRadius: '20px',
            padding: '0.95rem 1rem',
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 500,
            fontSize: '0.9rem',
            backgroundColor: theme.secondary,
            boxShadow: theme.type === 'dark' ? 'inset 3px 3px 6px #ffffff10, inset -3px -3px 6px #00000060' : 'inset 3px 3px 6px #ffffffbd, inset -3px -3px 6px #00000030',
            "&::placeholder": {
                color: theme.tertiary,
            },
            [t.breakpoints.down('sm')]: {
                width: '350px',
            },
        },
        home: {
            color: theme.secondary,
            position: 'absolute',
            top: 25,
            left: 25,
            padding: '7px',
            borderRadius: '50%',
            boxSizing: 'content-box',
            fontSize: '2rem',
            cursor: 'pointer',
            boxShadow: theme.type === 'dark' ? '3px 3px 6px #ffffff40, -3px -3px 6px #00000050' : '3px 3px 6px #ffffff40, -3px -3px 6px #00000050',
            transition: 'all 0.3s ease-in-out',
            "&:hover":
            {
                color: theme.tertiary,
                transform: 'scale(1.1)',
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
    }));

    const classes = useStyles();

    return (
        <BlogPageUI
            theme={theme}
            classes={classes}
            filteredArticles={filteredArticles}
            setSearch={setSearch}
            search={search}
        />
    )
}

export default BlogPage
